import axios from "axios";
import { idKeys, endpoints } from "@/helpers/endpoints";

export default {
  state: () => ({
    channels: sessionStorage.getItem("availableChannels") || [],
    selected: sessionStorage.getItem("channelSelected") || null,
    generalInfo: sessionStorage.getItem("channelGeneralInfo") || null,
    advanceInfo: sessionStorage.getItem("channelAdvanceInfo") || null,
    advanceConfig: sessionStorage.getItem("channelAdvanceConfig") || null,
    country: sessionStorage.getItem("country") || null,
    location: sessionStorage.getItem("location") || null,
    styles: {
      main_logo: sessionStorage.getItem("main_logo") || "",
      titulo_color: sessionStorage.getItem("titulo_color") || "",
      texto_color: sessionStorage.getItem("texto_color") || "",
      icono_color: sessionStorage.getItem("icono_color") || "",
      menu_color: sessionStorage.getItem("menu_color") || "",
      navbar_color: sessionStorage.getItem("navbar_color") || "",
      boton_color: sessionStorage.getItem("boton_color") || "",
      box_color: sessionStorage.getItem("box_color") || "",
      background_color: sessionStorage.getItem("background_color") || "",
      extra_color: sessionStorage.getItem("extra_color") || "",
    },
  }),
  getters: {
    getChannels(state) {
      let data;
      try {
        data = JSON.parse(state.channels).sort((a, b) =>
          a.canal_descripcion.localeCompare(b.canal_descripcion)
        );
      } catch {
        data = state.channels || [];
      }
      return data;
    },
    getChannelSelected(state) {
      let data;
      try {
        data = JSON.parse(state.selected);
      } catch {
        data = state.selected || null;
      }
      return data;
    },
    getGeneralInfo(state) {
      let data;
      try {
        data = JSON.parse(state.generalInfo);
      } catch {
        data = state.generalInfo || null;
      }
      return data;
    },
    getAdvanceInfo(state) {
      let data;
      try {
        data = JSON.parse(state.advanceInfo);
      } catch {
        data = state.advanceInfo || null;
      }
      return data;
    },
    getAdvanceConfig(state) {
      let data;
      try {
        data = JSON.parse(state.advanceConfig);
      } catch {
        data = state.advanceInfo || null;
      }
      return data;
    },
    getCustomStyles(state) {
      return state.styles;
    },
    getCountry(state) {
      let data;
      try {
        data = JSON.parse(state.country);
      } catch {
        data = state.country || null;
      }
      return data;
    },
    getLocation(state) {
      let data;
      try {
        data = JSON.parse(state.location);
      } catch {
        data = state.location || null;
      }
      return data;
    },
  },
  mutations: {
    setChannels(state, payload) {
      const jsonData = JSON.stringify(payload);
      sessionStorage.setItem("availableChannels", jsonData);
      state.channels = jsonData;
    },
    setSelectedChannel(state, payload) {
      const jsonData = JSON.stringify(payload);
      sessionStorage.setItem("channelSelected", jsonData);
      state.selected = jsonData;
    },
    setGeneralInfo(state, payload) {
      const jsonData = JSON.stringify(payload);
      sessionStorage.setItem("channelGeneralInfo", jsonData);
      state.generalInfo = jsonData;
    },
    setAdvanceInfo(state, payload) {
      const jsonData = JSON.stringify(payload);
      sessionStorage.setItem("channelAdvanceInfo", jsonData);
      state.advanceInfo = jsonData;
    },
    setAdvanceConfig(state, payload) {
      const jsonData = JSON.stringify(payload);
      sessionStorage.setItem("channelAdvanceConfig", jsonData);
      state.advanceConfig = jsonData;
    },
    setCustomization(state, payload) {
      const jsonData = JSON.stringify(payload);
      sessionStorage.setItem("channelGeneralInfo", jsonData);
      state.generalInfo = jsonData;
    },
    setCountry(state, payload) {
      const jsonData = JSON.stringify(payload);
      sessionStorage.setItem("country", jsonData);
      state.country = jsonData;
    },
    setLocation(state, payload) {
      const jsonData = JSON.stringify(payload);
      sessionStorage.setItem("location", jsonData);
      state.location = jsonData;
    },
    //Style Setters
    setMainLogo(state, imagen) {
      sessionStorage.setItem("main_logo", imagen);
      state.styles.main_logo = imagen;
    },
    setTituloColor(state, color) {
      sessionStorage.setItem("titulo_color", color);
      state.styles.titulo_color = color;
    },
    setTextoColor(state, color) {
      sessionStorage.setItem("texto_color", color);
      state.styles.texto_color = color;
    },
    setIconoColor(state, color) {
      sessionStorage.setItem("icono_color", color);
      state.styles.icono_color = color;
    },
    setMenuColor(state, color) {
      sessionStorage.setItem("menu_color", color);
      state.styles.menu_color = color;
    },
    setNavbarColor(state, color) {
      sessionStorage.setItem("navbar_color", color);
      state.styles.navbar_color = color;
    },
    setBotonColor(state, color) {
      sessionStorage.setItem("boton_color", color);
      state.styles.boton_color = color;
    },
    setBoxColor(state, color) {
      sessionStorage.setItem("box_color", color);
      state.styles.box_color = color;
    },
    setBackgroundColor(state, color) {
      sessionStorage.setItem("background_color", color);
      state.styles.background_color = color;
    },
    setExtraColor(state, color) {
      sessionStorage.setItem("extra_color", color);
      state.styles.extra_color = color;
    },
  },
  actions: {
    READ_STYLES({ commit }, data) {
      // data => objeto de personalizacion del canal
      if (data.colores) {
        data.colores.forEach((element) => {
          if (element.tipo) {
            let commitCode;
            switch (element.tipo) {
              case "TIT":
                commitCode = "setTituloColor";
                break;
              case "TEX":
                commitCode = "setTextoColor";
                break;
              case "MEN":
                commitCode = "setMenuColor";
                break;
              case "NAV":
                commitCode = "setNavbarColor";
                break;
              case "BOT":
                commitCode = "setBotonColor";
                break;
              case "ICO":
                commitCode = "setIconoColor";
                break;
              case "BOX":
                commitCode = "setBoxColor";
                break;
              case "BAC":
                commitCode = "setBackgroundColor";
                break;

              default:
                commitCode = "setExtraColor";
                break;
            }
            commit(commitCode, element.color);
          }
        });
      }
      if (data.logo) {
        axios.get(data.logo.url).then((response) => {
          commit("setMainLogo", response.data.data.url || "");
        });
      }
    },
    SET_CHANNEl({ commit, dispatch }, payload) {
      commit("setSelectedChannel", {
        id: payload.id_canal,
        description: payload.canal_descripcion,
        code: payload.codigo,
        owner: payload.responsable,
      });
      commit("setGeneralInfo", payload);
      dispatch("READ_STYLES", payload.personalizacion_corporativa);
    },
    REQUEST_CHANNEL_INFO({ dispatch }, channelId) {
      return new Promise((resolve) => {
        dispatch("REQUEST_CHANNEL_GENERAL_INFO_BY_ID", channelId);
        dispatch("REQUEST_CHANNEL_ADVANCE_INFO_BY_ID", channelId);
        dispatch("REQUEST_CHANNEL_ADVANCE_CONFIG_BY_ID", channelId);
        dispatch("Users/deleteUserData", null, { root: true });
        resolve("ok");
      });
    },
    REQUEST_CHANNEL_GENERAL_INFO_BY_ID({ dispatch, commit }, payload) {
      // Payload => Id del canal
      const url = `${endpoints.channel.generalInfo}/${payload.id_canal}`;
      return new Promise((resolve, reject) => {
        axios({
          method: "GET",
          url,
        })
          .then((response) => {
            const selectedChannel = response.data.data.result;
            selectedChannel.id_canal = payload.id_canal;
            selectedChannel.responsable = payload.responsable || false;
            selectedChannel.codigo = payload.codigo || "";
            selectedChannel.canal_descripcion = payload.canal_descripcion || "";
            // Datos del pais que provienen del canal
            const {
              pais: id,
              pais_codigo: code,
              pais_descripcion: description,
            } = selectedChannel;
            commit("setCountry", { id, code, description });
            dispatch("REQUEST_COUNTRY_DOCUMENT_TYPE", code);
            dispatch("REQUEST_COUNTRY_STRUCTURE", code);
            // ----- ----- -----
            dispatch("SET_CHANNEl", selectedChannel);
            resolve(selectedChannel);
          })
          .catch(() =>
            reject(
              "No se ha logrado recuperar la informacion general del canal"
            )
          );
      });
    },
    REQUEST_CHANNEL_ADVANCE_INFO_BY_ID({ commit }, payload) {
      const url = `${endpoints.channel.advanceInfo}/${payload.id_canal}`;
      return new Promise((resolve, reject) => {
        axios({
          method: "GET",
          url,
        })
          .then((response) => {
            const selectedChannel = response.data.data.result;
            commit("setAdvanceInfo", selectedChannel);
            resolve(selectedChannel);
          })
          .catch(() =>
            reject(
              "No se ha logrado recuperar la informacion avanzada del canal"
            )
          );
      });
    },
    REQUEST_CHANNEL_ADVANCE_CONFIG_BY_ID({ commit }, payload) {
      const url = endpoints.channel.advanceConfig.replace(
        idKeys.channel,
        payload.id_canal
      );
      return new Promise((resolve, reject) => {
        axios({
          method: "GET",
          url,
        })
          .then((response) => {
            const selectedChannel = response.data.data.configuracion_avanzada;
            commit("setAdvanceConfig", selectedChannel);
            resolve(selectedChannel);
          })
          .catch(() =>
            reject(
              "No se ha logrado recuperar la configuracion avanzada del canal"
            )
          );
      });
    },
    REQUEST_COUNTRY_DOCUMENT_TYPE({ commit, getters }, payload) {
      const path = `${endpoints.country.docs}/${payload}`;
      return new Promise((resolve, reject) => {
        axios
          .get(path)
          .then((response) => {
            const result = response.data.data.data;
            const countryData = { ...getters.getCountry, docType: result };
            commit("setCountry", countryData);
            resolve(result);
          })
          .catch((error) => {
            const rejectData = {
              info: "No se ha logrado recuperar los tipos de documentos del pais",
              result: error.response?.data,
            };
            reject(rejectData);
          });
      });
    },
    REQUEST_COUNTRY_STRUCTURE({ commit }, payload) {
      const path = `${endpoints.country.base}/${payload}`;
      return new Promise((resolve, reject) => {
        axios
          .get(path)
          .then((response) => {
            const result = response.data.data.regiones;
            let regiones = [],
              provincias = [],
              comunas = [];
            result.forEach((reg) => {
              regiones.push({
                label: reg.nombre,
                value: reg.codigo,
              });
              reg.provincias.forEach((prov) => {
                provincias.push({
                  label: prov.nombre,
                  value: prov.codigo,
                  region: reg.codigo,
                });
                prov.comunas.map((com) => {
                  comunas.push({
                    label: com.nombre,
                    value: com.codigo,
                    provincia: prov.codigo,
                    region: reg.codigo,
                  });
                });

                return;
              });
            });
            commit("setLocation", { regiones, provincias, comunas });
            resolve(result);
          })
          .catch((error) => {
            const rejectData = {
              info: "No se ha logrado recuperar la estructura de territorio de un país",
              result: error.response?.data,
            };
            reject(rejectData);
          });
      });
    },
  },
};
