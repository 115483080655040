export default [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Authentication/Login.vue"),
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import("@/views/Authentication/SignIn.vue"),
  },
  {
    path: "/new-password",
    name: "NewPassword",
    component: () => import("@/views/Authentication/InsertNewPassword.vue"),
  },
  {
    path: "/recover-password",
    name: "Recover",
    component: () => import("@/views/Authentication/RecoverAccount.vue"),
  },
  {
    path: "/signup",
    name: "Signup",
    component: () => import("@/views/Authentication/SignUp.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/views/Authentication/Logout.vue"),
  },
];
